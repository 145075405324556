<template>
    <v-app>
        <div class="frame-popup">
            <header>
                <h1>SMS 발송이력</h1>
                <div class="is-right">
                    <v-btn class="jh-btn is-icon" title="닫기" @click="close"><i class="jh-icon-close-lg is-white"></i></v-btn>
                </div>
            </header>
            <main><E030102 /></main>
        </div>
        <v-dialog v-model="progress_bar" content-class="jh-prograss-bar" elevation="10">
            <v-progress-circular
                :size="60"
                :width="6"
                color="secondary"
                indeterminate
            ></v-progress-circular>
        </v-dialog>
    </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import E030102 from "./E030102.vue";

export default {
    name: "MENU_E030102P01", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {},
    components: {
        E030102
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            progress_bar: "commonStore/GE_COMMON_PROGRESS_BAR",
        })
    },
    methods: {
        close() {
            self.close();
        },
    },
    created() {},
    mounted() {
        // 토큰 동기화를 위해 메인창에 window 객체에 팝업 windows 객체 저장
        // window 팝업에서 palette api를 호출할 경우 필수
        if (opener && opener.opener) {
            if (!opener.opener.popups) opener.opener.popups = [];
                opener.opener.popups.push(self);
        }
        else if (opener) {
            if (!opener.popups) opener.popups = [];
                opener.popups.push(self);
        }
    },
    watch: {}
};
</script>

<style>
</style>